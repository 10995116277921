<template>
  <li :key="action.id" class="py-1 px-5">
    <strong>{{ Number(action.created) | date('l LTS') }}:</strong>
    <span class="ml-1">
      <RouterLink v-if="route" :to="route">
        {{ action.message.replace('cytomine', 'imageDx') }}
      </RouterLink>
      <template v-else>{{
        action.message.replace('cytomine', 'imageDx')
      }}</template>
    </span>
    <div
      v-if="previewUrl"
      class="box preview absolute z-100 bottom-0 left-1/2 -translate-x-1/2"
    >
      <img :src="previewUrl" class="max-w-320 max-h-320" />
    </div>
  </li>
</template>

<script>
const ANNOT = 1;
const IMAGE = 2;
const PROJECT = 3;
const USER = 4;
const PROPERTY = 5;

export default {
  name: 'ActivityLogsItem',
  props: {
    action: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      previewUrl: null,
      route: null,
      projectId: '',
    };
  },
  computed: {
    /** @returns {object} */
    actionData() {
      return JSON.parse(this.action.data);
    },
    type() {
      //TODO: Add other activity services...e.g.: descriptionService
      switch (this.action.serviceName) {
        case 'imageInstanceService':
          return IMAGE;
        case 'userAnnotationService':
        case 'annotationTermService':
          // Annotations and AnnotationTerms
          return ANNOT;
        case 'projectRepresentativeUserService':
          // Representative Users
          return USER;
        case 'propertyService':
          // Annot, Image, Project Properties
          return PROPERTY;
        default:
          return PROJECT;
      }
    },
  },
  created() {
    this.projectId = this.action.project;

    switch (this.action.className) {
      case 'be.cytomine.command.AddCommand':
        this.addCommandHandler();
        break;
      case 'be.cytomine.command.EditCommand':
        this.editCommandHandler();
        break;
      case 'be.cytomine.command.DeleteCommand':
        // object no longer available => cannot display preview or provide link
        break;
      default:
        console.log('Unknown command type ' + this.action.className);
    }
  },
  methods: {
    annotationHandler(annot) {
      if (annot.userannotation) {
        // Annotation terms
        this.route = `/project/${this.projectId}/image/${annot.image}/annotation/${annot.userannotation}`;
      } else {
        // Annotations
        this.previewUrl = `${annot.url}?maxSize=125&complete=true&thickness=2&increaseArea=1.25&draw=true`;
        this.route = `/project/${this.projectId}/image/${annot.image}/annotation/${annot.id}`;
      }
    },
    imageHandler(img) {
      if (img.deleted) {
        return;
      }
      this.previewUrl = img.thumb;
      this.route = `/project/${this.projectId}/image/${img.id}`;
    },
    // TODO: Consider Permissions: see if we want this acitivty to reroute to Project Information page or just images list?
    projectHandler() {
      this.route = `/project/${this.projectId}`;
    },
    userHandler(user) {
      if (user.deleted) {
        return;
      }
      this.route = `/project/${this.projectId}`;
    },
    propertyHandler(prop) {
      if (prop.deleted) {
        return;
      }
      const domainClassName = prop.domainClassName;
      if (domainClassName === 'be.cytomine.ontology.UserAnnotation') {
        // Annot Props
        this.route = `/project/${this.projectId}/image/${prop.image}/annotation/${prop.domainIdent}`;
      } else if (domainClassName === 'be.cytomine.image.ImageInstance') {
        // Image Props
        this.route = `/project/${this.projectId}/image/${prop.domainIdent}`;
      } else {
        // Project Props
        this.route = `/project/${this.projectId}`;
      }
    },
    addCommandHandler() {
      switch (this.type) {
        case ANNOT:
          this.annotationHandler(this.actionData);
          break;
        case IMAGE:
          this.imageHandler(this.actionData);
          break;
        case PROJECT:
          this.projectHandler();
          break;
        case USER:
          this.userHandler(this.actionData);
          break;
        case PROPERTY:
          this.propertyHandler(this.actionData);
          break;
      }
    },
    editCommandHandler() {
      switch (this.type) {
        case ANNOT:
          // erratic core behaviour -> need to keep the defined new?Annotation property
          this.annotationHandler(
            this.actionData.newUserAnnotation ||
              this.actionData.newReviewedAnnotation ||
              this.actionData.newAlgoAnnotation ||
              this.actionData.newAnnotation
          );
          break;
        case IMAGE:
          this.imageHandler(this.actionData.newImageInstance);
          break;
        case PROJECT:
          this.projectHandler();
          break;
        case USER:
          this.userHandler(this.actionData);
          break;
        case PROPERTY:
          this.propertyHandler(this.actionData);
          break;
      }
    },
  },
};
</script>

<style scoped>
li:hover {
  background: rgba(0, 0, 0, 0.025);
}

.preview {
  display: none;
}

span:hover + .preview {
  display: block;
}
</style>
